import { LoginEventConsumer } from '@/data/events/login-event/login-event-consumer';
import { LogoutEventConsumer } from '@/data/events/logout-event/logout-event-consumer';
import { LoginEvent } from '@/domain/events/login-event';
import { LogoutEvent } from '@/domain/events/logout-event';
import { AxiosHttpClient } from '@/infra/http/axios-http-client';
import { BrowserRouter } from '@/infra/router/browser-router';
var EventBuilder = /** @class */ (function () {
    function EventBuilder(appSessionManager, eventBus) {
        this.appSessionManager = appSessionManager;
        this.eventBus = eventBus;
    }
    EventBuilder.prototype.subscribeLoginEvent = function () {
        this.eventBus.subscribe({
            event: LoginEvent,
            consumer: new LoginEventConsumer(this.appSessionManager, BrowserRouter.getInstance()),
        });
        return this;
    };
    EventBuilder.prototype.subscribeLogoutEvent = function () {
        this.eventBus.subscribe({
            event: LogoutEvent,
            consumer: new LogoutEventConsumer({
                httpClient: new AxiosHttpClient(),
                refreshUrl: process.env.REFRESH_TOKEN_URL,
                applicationSessionManager: this.appSessionManager,
                router: BrowserRouter.getInstance(),
            }),
        });
        return this;
    };
    return EventBuilder;
}());
export { EventBuilder };
