import toastr from 'toastr';
var BrowserRouter = /** @class */ (function () {
    function BrowserRouter() {
        var _this = this;
        this.messages = {
            refreshError: 'Não foi possível realizar a renovação da sessão.',
            invalidSession: 'Não foi possível validar a sessão. Por favor, efetue o login.',
            userNotAllowed: 'Seu usuário não possui acesso a plataforma.',
            ProviderIdentityNotFound: 'Usuário não encontrado.',
            UserFoundIsDeactivated: 'Usuário encontrado está desativado.',
            UserFoundIsBlocked: 'Usuário encontrado está bloqueado.',
        };
        window.addEventListener('load', function () {
            var params = new URLSearchParams(window.location.search);
            if (!params.get('message'))
                return;
            toastr.error(_this.messages[params.get('message')]);
        });
    }
    BrowserRouter.getInstance = function () {
        if (!this.instance) {
            this.instance = new BrowserRouter();
        }
        return this.instance;
    };
    BrowserRouter.prototype.navigate = function (location, messageCode) {
        var isValidMessageCode = messageCode && messageCode in this.messages;
        var messageParam = isValidMessageCode ? "?message=".concat(messageCode) : '';
        window.location.replace("".concat(location).concat(messageParam));
    };
    BrowserRouter.prototype.refresh = function () {
        window.location.reload();
    };
    return BrowserRouter;
}());
export { BrowserRouter };
