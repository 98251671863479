var ApplicationSessionManagerImp = /** @class */ (function () {
    function ApplicationSessionManagerImp(inputParams) {
        this.appData = {
            authToken: '',
            refreshToken: '',
            user: null,
            isLogged: false,
            isEinstein: false,
            isEnterprise: false,
            isHealthAssist: false,
        };
        this.spaToken = { token: null };
        this.DEFAULT_SAAS_NAME = 'EINSTEIN';
        var cookies = inputParams.cookies, encoder = inputParams.encoder, router = inputParams.router;
        this.cookies = cookies;
        this.encoder = encoder;
        this.router = router;
    }
    ApplicationSessionManagerImp.prototype.validateToken = function (isTokenRequired) {
        if (this.spaToken.token)
            return;
        var sessionToken = this.getSessionToken();
        if (!sessionToken) {
            this.redirect(isTokenRequired);
            return;
        }
        try {
            var data = this.encoder.decode(sessionToken);
            this.setUserSession(data);
        }
        catch (e) {
            console.error('An error ocurred while retrieving user info', e);
            this.redirect(isTokenRequired);
        }
    };
    ApplicationSessionManagerImp.prototype.getSessionToken = function () {
        return this.cookies.get('portal-empresas-session');
    };
    ApplicationSessionManagerImp.prototype.redirect = function (isTokenRequired) {
        if (!isTokenRequired)
            return;
        this.clearAppData();
        this.router.navigate('/', 'invalidSession');
    };
    ApplicationSessionManagerImp.prototype.clearAppData = function () {
        this.appData.authToken = '';
        this.appData.refreshToken = '';
        this.appData.user = null;
        this.appData.isLogged = false;
        this.spaToken.token = null;
        this.cookies.removeAll();
    };
    ApplicationSessionManagerImp.prototype.setUserSession = function (data) {
        this.appData.authToken = data.authToken;
        this.appData.refreshToken = data.refreshToken;
        this.appData.user = data.user;
        this.appData.isEnterprise = this.checkUserType(data.user, 'isEnteprise');
        this.appData.isHealthAssist = this.checkUserType(data.user, 'isHealthAssist');
        this.appData.isEinstein = process.env.SAAS_NAME === this.DEFAULT_SAAS_NAME;
        this.appData.isLogged = true;
        this.encodeAppData();
        this.setAppCookies();
    };
    ApplicationSessionManagerImp.prototype.checkUserType = function (user, key) {
        return user.perfis.some(function (profile) { return profile[key]; });
    };
    ApplicationSessionManagerImp.prototype.encodeAppData = function () {
        this.spaToken.token = this.encoder.encode({
            authToken: this.appData.authToken,
            refreshToken: this.appData.refreshToken,
            user: this.appData.user,
        });
    };
    ApplicationSessionManagerImp.prototype.setAppCookies = function () {
        this.cookies.set({
            key: 'portal-empresas-user',
            value: this.appData.user,
        });
        this.cookies.set({
            key: 'portal-empresas-token',
            value: this.appData.authToken,
        });
        this.cookies.set({
            key: 'portal-empresas-session',
            value: this.spaToken.token,
        });
    };
    ApplicationSessionManagerImp.prototype.getUserSession = function () {
        return this.appData;
    };
    return ApplicationSessionManagerImp;
}());
export { ApplicationSessionManagerImp };
